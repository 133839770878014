import React from "react"

import CustomerLogoEdf from "../images/svgs/customer_logo_edf.svg"
import CustomerLogoGumtree from "../images/svgs/customer_logo_gumtree.svg"
import CustomerLogoKpmg from "../images/svgs/customer_logo_kpmg.svg"
import CustomerLogoMgh from "../images/svgs/customer_logo_mgh.svg"
import CustomerLogoPb from "../images/svgs/customer_logo_pb.svg"
import CustomerLogoStatista from "../images/svgs/customer_logo_statista.svg"

export default function LogoCloud() {
  return (
    <div class="bg-white relative">
      <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <p class="text-center text-base font-semibold uppercase text-gray-600 tracking-wider">
          Trusted by the best
        </p>
        <div class="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
          <div class="col-span-1 flex justify-center py-2 px-4 md:py-4 md:px-16 bg-silver">
            <CustomerLogoEdf alt="EDF"></CustomerLogoEdf>
          </div>
          <div class="col-span-1 flex justify-center py-2 px-4 md:py-4 md:px-16 bg-silver">
            <CustomerLogoGumtree alt="Gumtree"></CustomerLogoGumtree>
          </div>
          <div class="col-span-1 flex justify-center py-2 px-4 md:py-4 md:px-16 bg-silver">
            <CustomerLogoKpmg alt="KPMG"></CustomerLogoKpmg>
          </div>
          <div class="col-span-1 flex justify-center py-2 px-4 md:py-4 md:px-16 bg-silver">
            <CustomerLogoMgh alt="McGraw Hill"></CustomerLogoMgh>
          </div>
          <div class="col-span-1 flex justify-center py-2 px-4 md:py-4 md:px-16 bg-silver">
            <CustomerLogoPb alt="Pitney Bowes"></CustomerLogoPb>
          </div>
          <div class="col-span-1 flex justify-center py-2 px-4 md:py-4 md:px-16 bg-silver">
            <CustomerLogoStatista alt="Statista"></CustomerLogoStatista>
          </div>
        </div>
      </div>
    </div>
  )
}
