import React from "react"

import LogoCloud from "../components/logo-cloud"
import StartTrialBtn from "src/components/startTrialBtn"

const heroSplash = props => {
  return (
    <>
      <div class="lg:grid lg:grid-cols-12 lg:gap-8 py-16">
        <div class="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
          <p>
            <span class="mt-1 block text-4xl tracking-tight font-extrabold">
              <span class="block text-gray-900" dangerouslySetInnerHTML={{ __html: props.headline }}/>
            </span>
          </p>
          <p
            class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl"
            dangerouslySetInnerHTML={{ __html: props.splashBodyText }}
          ></p>
          <div class="mt-6">
            <StartTrialBtn
              label="Start free trial"
              destination="https://app.nickelled.com/account/signup"
            ></StartTrialBtn>
          </div>
        </div>
        <div class="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
          <img
            class="w-full"
            src={props.splashImageUrl}
            alt=""
          />
        </div>
      </div>
      <LogoCloud></LogoCloud>
    </>
  )
}

export default heroSplash
