import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "src/components/layout"

import SEO from "src/components/seo"

import HeroSplash from "src/components/heroSplash"

import NickelledLogo from "../images/svgs/nickelled-logo-o.svg"

import * as onePagerStyles from "./onePager.module.css"
import { Helmet } from "react-helmet"

export default function OnePager(post) {
  const currentArticle = post.data.markdownRemark

  const [showSections, toggleSections] = useState(true)
  const sectionToggle = () => toggleSections(!showSections)

  const schemaType = currentArticle.frontmatter.type
  const schemaContent = currentArticle.frontmatter.schema

  return (
    <Layout>
      <SEO
        title={currentArticle.frontmatter.title}
        description={currentArticle.frontmatter.description}
      />
      <Helmet>
        {schemaType === "howto" && (
          <script type="application/ld+json">
            {currentArticle.frontmatter.schema}
          </script>
        )}
      </Helmet>
      <HeroSplash
        headline={currentArticle.frontmatter.splashHeadline}
        splashBodyText={currentArticle.frontmatter.splashBodyText}
        splashImageUrl={
          currentArticle.frontmatter.splashImageLocation.publicURL
        }
      ></HeroSplash>
      <div class=" flex  bg-white">
        <div class="md:hidden">
          <div
            className={
              (showSections ? "opacity-0 hidden" : "opacity-100") +
              " fixed inset-0 flex z-40"
            }
          >
            <div class="fixed inset-0">
              <div class="absolute inset-0 bg-gray-600 opacity-75"></div>
            </div>

            <div class="relative flex-1 flex flex-col max-w-xs w-full bg-white">
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={sectionToggle}
                >
                  <span class="sr-only">Close sidebar</span>
                  <svg
                    class="h-6 w-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div class="flex-shrink-0 flex items-center px-4">
                  <NickelledLogo class="h-8 w-auto"></NickelledLogo>
                </div>
                <nav class="mt-5 px-2 space-y-1">
                  <div
                    className={onePagerStyles.markdownTableOfContents}
                    dangerouslySetInnerHTML={{
                      __html: post.data.markdownRemark.tableOfContents,
                    }}
                  />
                </nav>
              </div>
            </div>
            <div class="flex-shrink-0 w-14"></div>
          </div>
        </div>

        <div class="hidden md:flex md:flex-shrink-0">
          <div class="flex flex-col w-64">
            <div class="flex flex-col h-0 flex-1">
              <div class="flex-1 flex flex-col overflow-y-auto">
                <nav class="mt-5 flex-1 px-2 bg-white space-y-1">
                  <div
                    className={onePagerStyles.markdownTableOfContents}
                    dangerouslySetInnerHTML={{
                      __html: post.data.markdownRemark.tableOfContents,
                    }}
                  />
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col w-0 flex-1 overflow-hidden">
          <div class="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
            <button
              class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange"
              onClick={sectionToggle}
            >
              <span class="sr-only">Open sidebar</span>
              <svg
                class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
          <main
            class="flex-1 relative z-0 overflow-y-auto focus:outline-none"
            tabindex="0"
          >
            <div class="py-6">
              <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <h1 class="text-4xl font-semibold text-oOrange">
                  {currentArticle.frontmatter.title}
                </h1>
              </div>
              <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <div class="md:text-lg">
                  <div
                    className={onePagerStyles.onePagerContent}
                    dangerouslySetInnerHTML={{ __html: currentArticle.html }}
                  />
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </Layout>
  )
}

export const post = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        splashHeadline
        splashBodyText
        splashImageLocation {
          relativePath
          publicURL
        }
        type
        schema
      }
      tableOfContents(maxDepth: 2)
    }
  }
`
